.audio-control {
  display: flex;
  justify-content: center;
  align-items: center;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
  }
}
